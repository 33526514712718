import { Provider } from "@dzangolab/react-form";
import { useTranslation } from "@dzangolab/react-i18n";
import { Modal } from "@dzangolab/react-ui";
import { Release, Service } from "core";
import { toast } from "react-toastify";
import { z } from "zod";

import AddReleaseServiceFormFields from "./AddReleaseServiceFormFields";
import { RELEASE_SERVICE_ALREADY_ADDED } from "../../../../constants";
import { useCurrentWorkspace } from "../../../../hooks/UseCurrentWorkspace";
import { useAddReleaseServiceMutation } from "../../../../redux/apis/release";

interface Properties {
  release: Release;
  workspaceServices: Service[];
  onHide: () => void;
  visible: boolean;
}

const AddReleaseServiceDialog: React.FC<Properties> = (properties) => {
  const { onHide, visible, release, workspaceServices } = properties;
  const { t } = useTranslation("appOverview");
  const [addReleaseService, { isLoading: isAddingReleaseService }] =
    useAddReleaseServiceMutation();
  const { refetchWorkspaceAndRelease } = useCurrentWorkspace();

  const formValidationSchema = z.object({
    workspaceServiceId: z.number(),
    releaseAppId: z.number(),
  });

  const onSubmit = async (data: any) => {
    const response = await addReleaseService({
      releaseAppId: data.releaseAppId,
      releaseId: release.id,
      workspaceServiceId: data.workspaceServiceId,
    });

    if ("error" in response) {
      if (
        (response as any).error?.data?.message === RELEASE_SERVICE_ALREADY_ADDED
      ) {
        toast.error(t("release-service.add.message.service-already-added"));
      } else {
        toast.error(t("release-service.add.message.error"));
      }
    } else {
      toast.success(t("release-service.add.message.success"));
      refetchWorkspaceAndRelease();
      onHide();
    }
  };

  return (
    <Modal
      onHide={onHide}
      visible={visible}
      header={t("release-service.add.title")}
      className="add-release-service-dialog"
    >
      <Provider onSubmit={onSubmit} validationSchema={formValidationSchema}>
        <AddReleaseServiceFormFields
          releaseApps={release?.apps}
          isSubmitting={isAddingReleaseService}
          workspaceServices={workspaceServices}
        />
      </Provider>
    </Modal>
  );
};

export default AddReleaseServiceDialog;

/* eslint-disable no-console */
import { useState, useEffect, useCallback } from "react";

const useLocalStorage = <T>(
  key: string,
  initialValue: T
): [T, (value: T) => void] => {
  const readValue = (): T => {
    if (typeof window === "undefined") {
      return initialValue;
    }

    try {
      const item = window.localStorage.getItem(key);

      return item ? JSON.parse(item) : initialValue;
    } catch (error) {
      console.warn(`Error reading localStorage key “${key}”:`, error);

      return initialValue;
    }
  };

  const [storedValue, setStoredValue] = useState<T>(readValue);

  const setValue = useCallback(
    (value: T) => {
      if (typeof window === "undefined") {
        console.warn(
          `Tried setting localStorage key “${key}” even though environment is not a client`
        );

        return;
      }

      try {
        const valueToStore =
          value instanceof Function ? value(storedValue) : value;

        if (valueToStore) {
          setStoredValue(valueToStore);
          window.localStorage.setItem(key, JSON.stringify(valueToStore));

          window.dispatchEvent(new Event("localStorage-update"));
        }
      } catch (error) {
        console.warn(`Error setting localStorage key “${key}”:`, error);
      }
    },
    [key, storedValue]
  );

  useEffect(() => {
    const handleStorageChange = () => {
      try {
        const item = window.localStorage.getItem(key);

        setStoredValue(item ? JSON.parse(item) : initialValue);
      } catch (error) {
        console.error(error);
      }
    };

    window.addEventListener("localStorage-update", handleStorageChange);

    window.addEventListener("storage", handleStorageChange);

    return () => {
      // Clean up the event listeners
      window.removeEventListener("localStorage-update", handleStorageChange);
      window.removeEventListener("storage", handleStorageChange);
    };
  }, [initialValue, key]);

  return [storedValue, setValue];
};

export default useLocalStorage;

import { Service, ServiceCreateInput, ServiceUpdateInput } from "core";

import baseApi, { TagsEnum } from "./base-api";

const servicesApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getServices: build.query<Service[], { workspaceId: number }>({
      query: ({ workspaceId }) => {
        return {
          url: `workspaces/${workspaceId}/services`,
        };
      },
      providesTags: [TagsEnum.Services],
    }),

    createService: build.mutation<Service, ServiceCreateInput>({
      query: (data: ServiceCreateInput) => {
        return {
          url: `workspaces/${data.workspaceId}/services`,
          method: "POST",
          body: data,
        };
      },
      invalidatesTags: [TagsEnum.Service],
    }),

    stopService: build.query<any, { id: number; workspaceId: number }>({
      query: (input) => {
        return {
          url: `workspaces/${input.workspaceId}/services/${input.id}/stop`,
        };
      },
      providesTags: [TagsEnum.Service],
    }),

    deployService: build.query<any, { id: number; workspaceId: number }>({
      query: (input) => {
        return {
          url: `workspaces/${input.workspaceId}/services/${input.id}/deploy`,
        };
      },
      providesTags: [TagsEnum.Service],
    }),

    updateService: build.mutation<
      Service,
      { id: number; data: ServiceUpdateInput; workspaceId: number }
    >({
      query: ({ id, data, workspaceId }) => {
        return {
          url: `workspaces/${workspaceId}/services/${id}`,
          method: "PUT",
          body: data,
        };
      },
      invalidatesTags: [TagsEnum.Service],
    }),
  }),
});

export const {
  useCreateServiceMutation,
  useLazyGetServicesQuery,
  useLazyStopServiceQuery,
  useLazyDeployServiceQuery,
  useUpdateServiceMutation,
} = servicesApi;
export default servicesApi;

import { useTranslation } from "@dzangolab/react-i18n";
import {
  AcceptInvitation,
  AuthGoogleCallback,
  ChangePassword,
  EmailVerificationReminder,
  ForgotPassword,
  Profile,
  ResetPassword,
  Signup,
  UserContextType,
  UserType,
  VerifyEmail,
  useEmailVerification,
  userContext,
} from "@dzangolab/react-user";
import { useContext } from "react";
import {
  LoaderFunctionArgs,
  RouterProvider,
  createBrowserRouter,
  redirect,
} from "react-router-dom";

import { feature } from "./config";
import { ROUTES } from "./constants";
import BasicLayout from "./layouts/BasicLayout";
import PublicLayout from "./layouts/PublicLayout";
import WorkspacesLayout from "./layouts/WorkspacesLayout";
import Dashboard from "./views/Dashboard";
import DataModel from "./views/DataModel";
import Docs from "./views/Docs";
import Document from "./views/Docs/Document";
import DocsPlayground from "./views/DocsPlayground";
import ErrorBoundary from "./views/ErrorBoundary";
import Glossary from "./views/Glossary";
import AddFirstTermPage from "./views/Glossary/AddFirstTerm";
import AddGlossary from "./views/Glossary/AddGlossary";
import AddGlossaryEntry from "./views/Glossary/AddGlossaryEntry";
import EditGlossaryEntry from "./views/Glossary/EditGlossaryEntry";
import StartGlossaryPage from "./views/Glossary/StartGlossary";
import Loading from "./views/Loading";
import Login from "./views/Login";
import Overview from "./views/Overview";
import Repositories from "./views/Repositories";
import AddRepository from "./views/Repositories/Create";
import EditRepository from "./views/Repositories/Edit";
import WorkspaceServices from "./views/Services";
import DocumentationSettings from "./views/Settings/DocumentationSettings";
import CreateDocumentTypes from "./views/Settings/DocumentationSettings/CreateDocumentTypes";
import EditDocumentTypes from "./views/Settings/DocumentationSettings/EditDocumentTypes";
import GeneralSettings from "./views/Settings/GeneralSettings";
import GlossarySettings from "./views/Settings/GlossarySettings";
import Team from "./views/Team";
import WorkspaceReleases from "./views/WorkspaceReleases";
import Workspaces from "./views/Workspaces";
import AddWorkspace from "./views/Workspaces/Create";

const routes = ({
  user,
  emailVerificationEnabled,
  isEmailVerified,
  t,
}: {
  user: UserType | null;
  emailVerificationEnabled?: boolean;
  isEmailVerified?: boolean;
  t: any;
}) => {
  const publicRouteHandler = async ({ request }: LoaderFunctionArgs) => {
    if (!user) {
      return null;
    }

    const url = new URL(request.url);
    const redirectUrl = url.searchParams.get("redirect");

    if (redirectUrl && redirectUrl.length) {
      return redirect(redirectUrl);
    }

    if (emailVerificationEnabled && !isEmailVerified) {
      return redirect(ROUTES.VERIFY_EMAIL_REMINDER);
    }

    if (user.isProfileCompleted === false) {
      return redirect(ROUTES.PROFILE_COMPLETION);
    }

    return redirect(ROUTES.WORKSPACES);
  };

  const authRouteHandler = async () => {
    if (!user) {
      return redirect(ROUTES.LOGIN);
    }

    if (emailVerificationEnabled && !isEmailVerified) {
      return redirect(ROUTES.VERIFY_EMAIL_REMINDER);
    }

    if (user.isProfileCompleted === false) {
      return redirect(ROUTES.PROFILE_COMPLETION);
    }

    return null;
  };

  const emailVerificationRouteHandler = async ({
    request,
  }: LoaderFunctionArgs) => {
    if (!user) {
      return redirect(
        `${ROUTES.LOGIN}?redirect=${window.encodeURI(request.url)}`
      );
    }

    if (!emailVerificationEnabled || isEmailVerified) {
      return user.isProfileCompleted !== false
        ? redirect(ROUTES.HOME)
        : redirect(ROUTES.PROFILE_COMPLETION);
    }

    return null;
  };

  const profileCompletionRouteHandler = async ({
    request,
  }: LoaderFunctionArgs) => {
    if (!user) {
      return redirect(
        `${ROUTES.LOGIN}?redirect=${window.encodeURI(request.url)}`
      );
    }

    if (emailVerificationEnabled && !isEmailVerified) {
      return redirect(ROUTES.VERIFY_EMAIL_REMINDER);
    }

    if (user.isProfileCompleted !== false) {
      return redirect(ROUTES.HOME);
    }

    return null;
  };

  let emailVerificationRoutes = [];

  if (emailVerificationEnabled) {
    emailVerificationRoutes.push(
      {
        path: ROUTES.VERIFY_EMAIL,
        element: <VerifyEmail />,
        loader: emailVerificationRouteHandler,
      },
      {
        path: ROUTES.VERIFY_EMAIL_REMINDER,
        element: <EmailVerificationReminder />,
        loader: emailVerificationRouteHandler,
      }
    );
  }

  let profileCompletionRoutes = [];

  if (user && user.isProfileCompleted !== undefined) {
    profileCompletionRoutes.push({
      path: ROUTES.PROFILE_COMPLETION,
      element: <Profile />,
      loader: profileCompletionRouteHandler,
    });
  }

  let documentationRoutes = [];

  if (feature("documentation")) {
    documentationRoutes.push(
      {
        path: ROUTES.WORKSPACE_DOCUMENT_TYPE_CREATE,
        element: <CreateDocumentTypes />,
        loader: authRouteHandler,
      },
      {
        path: ROUTES.DOCS_PLAYGROUND,
        element: <DocsPlayground />,
        loader: authRouteHandler,
      },
      {
        path: ROUTES.WORKSPACE_DOCUMENT_TYPE_EDIT,
        element: <EditDocumentTypes />,
        loader: authRouteHandler,
      },
      {
        path: ROUTES.WORKSPACE_DOCS,
        element: <Docs />,
        loader: authRouteHandler,
      },
      {
        path: ROUTES.WORKSPACE_DOCUMENT,
        element: <Document />,
        loader: authRouteHandler,
      },
      {
        path: ROUTES.WORKSPACE_SETTINGS_DOCUMENTATION,
        element: <DocumentationSettings />,
        loader: authRouteHandler,
      }
    );
  }

  let workspaceRoutesList = [
    { index: true, element: <Dashboard />, loader: authRouteHandler },
    {
      path: ROUTES.WORKSPACE_GLOSSARY,
      element: <Glossary />,
      loader: authRouteHandler,
    },
    {
      path: ROUTES.WORKSPACE_GLOSSARY_START,
      element: <StartGlossaryPage />,
      loader: authRouteHandler,
    },
    {
      path: ROUTES.WORKSPACE_GLOSSARY_ADD_FIRST_TERM,
      element: <AddFirstTermPage />,
      loader: authRouteHandler,
    },
    {
      path: ROUTES.WORKSPACE_GLOSSARY_NEW,
      element: <AddGlossary />,
      loader: authRouteHandler,
    },
    {
      path: ROUTES.WORKSPACE_GLOSSARY_ENTRY_EDIT,
      element: <EditGlossaryEntry />,
      loader: authRouteHandler,
    },
    {
      path: ROUTES.WORKSPACE_GLOSSARY_ENTRY_NEW,
      element: <AddGlossaryEntry />,
      loader: authRouteHandler,
    },
    {
      path: ROUTES.WORKSPACE_DATA_MODEL,
      element: <DataModel />,
      loader: authRouteHandler,
    },
    {
      path: ROUTES.WORKSPACE_RELEASE,
      element: <Overview />,
      loader: authRouteHandler,
    },
    {
      path: ROUTES.WORKSPACE_TEAM,
      element: <Team />,
      loader: authRouteHandler,
    },
    {
      path: ROUTES.WORKSPACE_SETTINGS_GENERAL,
      element: <GeneralSettings />,
      loader: authRouteHandler,
    },
    {
      path: ROUTES.WORKSPACE_SETTINGS_GLOSSARY,
      element: <GlossarySettings />,
      loader: authRouteHandler,
    },
    {
      path: ROUTES.WORKSPACE_RELEASES,
      element: <WorkspaceReleases />,
      loader: authRouteHandler,
    },
    {
      path: ROUTES.WORKSPACE_REPOSITORIES,
      element: <Repositories />,
      loader: authRouteHandler,
    },
    {
      path: ROUTES.WORKSPACE_REPOSITORY_CREATE,
      element: <AddRepository />,
      loader: authRouteHandler,
    },
    {
      path: ROUTES.WORKSPACE_REPOSITORY_EDIT,
      element: <EditRepository />,
      loader: authRouteHandler,
    },
    {
      path: ROUTES.SERVICES,
      element: <WorkspaceServices />,
      loader: authRouteHandler,
    },
    ...documentationRoutes,
  ];

  let basicRoutesList = [
    {
      path: ROUTES.WORKSPACES,
      element: <Workspaces />,
      loader: authRouteHandler,
    },
  ];

  let publicRoutesList = [
    {
      path: ROUTES.AUTH_GOOGLE,
      element: <AuthGoogleCallback />,
      loader: publicRouteHandler,
    },
    {
      path: ROUTES.FORGOT_PASSWORD,
      element: <ForgotPassword />,
      loader: publicRouteHandler,
    },
    {
      path: ROUTES.LOGIN,
      element: <Login />,
      loader: publicRouteHandler,
    },
    {
      path: ROUTES.RESET_PASSWORD,
      element: <ResetPassword />,
      loader: publicRouteHandler,
    },
    {
      path: ROUTES.SIGNUP,
      element: <Signup />,
      loader: publicRouteHandler,
    },
    {
      path: ROUTES.SIGNUP_INVITED_USER,
      element: <AcceptInvitation />,
      loader: publicRouteHandler,
    },
  ];

  let noHeaderBasicRoutesList = [
    {
      path: ROUTES.PROFILE,
      element: <Profile centered={false} />,
      loader: authRouteHandler,
    },
    {
      path: ROUTES.CHANGE_PASSWORD,
      element: <ChangePassword centered={false} />,
      loader: authRouteHandler,
    },
    ...emailVerificationRoutes,
    ...profileCompletionRoutes,
  ];

  return createBrowserRouter([
    {
      path: ROUTES.HOME,
      element: <WorkspacesLayout isUser={!!user} />,
      errorElement: <ErrorBoundary />,
      children: workspaceRoutesList,
    },
    {
      path: ROUTES.HOME,
      element: <BasicLayout isUser={!!user} displayHeader={false} />,
      errorElement: <ErrorBoundary />,
      children: noHeaderBasicRoutesList,
    },
    {
      path: ROUTES.HOME,
      element: (
        <BasicLayout isUser={!!user} headerTitle={t("newWorkspaceTitle")} />
      ),
      errorElement: <ErrorBoundary />,
      children: [
        {
          path: ROUTES.WORKSPACE_CREATE,
          element: <AddWorkspace />,
          loader: authRouteHandler,
        },
      ],
    },
    {
      path: ROUTES.HOME,
      element: <BasicLayout isUser={!!user} />,
      errorElement: <ErrorBoundary />,
      children: basicRoutesList,
    },
    {
      path: ROUTES.HOME,
      element: <PublicLayout />,
      errorElement: <ErrorBoundary />,
      children: publicRoutesList,
    },
  ]);
};

const Routers = () => {
  const { user, loading } = useContext(userContext) as UserContextType;
  const [emailVerificationEnabled, isEmailVerified] = useEmailVerification();

  const { t } = useTranslation("workspaceForm");

  if (loading) return <Loading />;

  return (
    <RouterProvider
      router={routes({
        user,
        emailVerificationEnabled,
        isEmailVerified,
        t,
      })}
    />
  );
};

export default Routers;

import { useTranslation } from "@dzangolab/react-i18n";
import {
  TDataTable as DataTable,
  Page,
  TableColumnDefinition,
} from "@dzangolab/react-ui";
import { formatDate } from "core";
import { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import { WORKSPACE_RELEASE_STATUS } from "../../constants";
import { useCurrentWorkspace } from "../../hooks/UseCurrentWorkspace";
import { useLazySetupAndBuildReleaseQuery } from "../../redux/apis/release";
import { useLazyGetWorkspaceReleasesQuery } from "../../redux/apis/workspaces";

const WorkspaceReleases = () => {
  const { t } = useTranslation("release");
  const navigate = useNavigate();

  const { workspace: currentWorkspace } = useCurrentWorkspace();

  const [fetchReleases, { data: releases, isFetching: isLoadingReleases }] =
    useLazyGetWorkspaceReleasesQuery();
  const [setupBuildRelease] = useLazySetupAndBuildReleaseQuery();

  useEffect(() => {
    if (currentWorkspace) {
      fetchReleases({ workspaceId: currentWorkspace.id });
    }
  }, [currentWorkspace, fetchReleases]);

  const selectRelease = (release: any) => {
    if (release && currentWorkspace) {
      navigate(`/workspaces/${currentWorkspace.slug}/releases/${release.slug}`);
    }
  };

  const getReleaseState = (release: any) => {
    const apps = release?.apps ?? [];

    const allBuilding = apps.every((app: any) => app.status === "building");
    const allRunning = apps.every((app: any) => app.status === "running");
    const anyReady = apps.some((app: any) => app.status === "ready");
    const anyStop = apps.some((app: any) => app.status === "stopped");
    const anyError = apps.some((app: any) => app.status === "error");

    const anyInvalidStatus = apps.some((app: any) => !app.status);

    const disablePlay = allBuilding || allRunning;
    const disableStop =
      allBuilding || anyReady || anyError || anyStop || anyInvalidStatus;

    return { disablePlay, disableStop };
  };

  const setupAndBuildActionHandler = async (selectedRelease: any) => {
    if (currentWorkspace && selectedRelease) {
      const response = await setupBuildRelease({
        workspaceId: currentWorkspace?.id,
        releaseId: selectedRelease.id,
      });

      if (response.error) {
        toast.error(t("messages.error.default"));

        return;
      }
    }
  };

  const columns: Array<TableColumnDefinition<any>> = [
    {
      accessorKey: "name",
      header: t("table.columns.name"),
      enableSorting: true,
      cell: ({ row: { original } }: { row: { original: any } }) => {
        return (
          <Link
            className="release-name-link"
            to={`/workspaces/${currentWorkspace.slug}/releases/${original.slug}`}
            onClick={(event_) => {
              event_.preventDefault();
              selectRelease(original);
            }}
          >
            {original.name}
          </Link>
        );
      },
    },
    {
      accessorKey: "type",
      header: t("table.columns.type"),
      width: "10rem",
      enableSorting: true,
    },
    {
      accessorKey: "status",
      accessorFn: (row) => row.status ?? undefined,
      header: t("table.columns.status"),
      width: "10rem",
      enableSorting: true,
      sortUndefined: "last",
      sortingFn: (rowA, rowB) => {
        const rowAStatus = rowA.original.status?.i18n[0].name || "";
        const rowBStatus = rowB.original.status?.i18n[0].name || "";

        return rowAStatus.localeCompare(rowBStatus);
      },
      cell: ({ row: { original } }: { row: { original: any } }) => {
        return original.status?.i18n[0].name ?? "";
      },
    },
    {
      accessorKey: "createdAt",
      header: t("table.columns.createdAt"),
      width: "10rem",
      enableSorting: true,
      cell: ({ row: { original } }: { row: { original: any } }) => {
        return formatDate(original.createdAt);
      },
    },
    {
      accessorKey: "state",
      header: t("table.columns.state"),
      width: "10rem",
      enableSorting: true,
      cell: ({ row: { original } }: { row: { original: any } }) => {
        if (original.closedAt) {
          return t("release-state.closed");
        }

        const { disablePlay, disableStop } = getReleaseState(original);

        if (disablePlay && disableStop) {
          return "";
        }

        if (disablePlay) {
          return WORKSPACE_RELEASE_STATUS.RUNNING;
        }

        if (disableStop) {
          return WORKSPACE_RELEASE_STATUS.READY;
        }

        return "";
      },
    },
  ];

  return (
    <Page title={t("releases")} loading={isLoadingReleases}>
      {releases && (
        <DataTable
          columns={columns}
          className="releases-table"
          isLoading={isLoadingReleases}
          data={releases}
          emptyTableMessage={t("table.empty")}
          initialSorting={[
            {
              id: "createdAt",
              desc: true,
            },
          ]}
          dataActionsMenu={{
            actions: [
              {
                label: t("table.actions.view"),
                onClick: (rowData: any) => {
                  selectRelease(rowData);
                },
              },
              {
                className: "table-action-warning",
                confirmationOptions: {
                  message: t("table.force-setup.message"),
                  header: t("table.force-setup.header"),
                },
                disabled: (data) => {
                  return !currentWorkspace.isCodeGenerated || data.closedAt;
                },
                label: t("table.actions.force-setup"),
                requireConfirmationModal: true,
                onClick: (rowData: any) => {
                  setupAndBuildActionHandler(rowData);
                },
              },
            ],
          }}
        />
      )}
    </Page>
  );
};

export default WorkspaceReleases;

import { Card, CardBody, CardHeader, Tag } from "@dzangolab/react-ui";
import React from "react";

interface AppCardProperties {
  name: string;
  url?: string | null;
  status?: {
    title?: string | null;
    color?: string;
  };
}

const AppCard: React.FC<AppCardProperties> = ({ name, status, url }) => {
  return (
    <Card className="app-card">
      <CardHeader>
        <div>
          <h3 className="app-card-title">{name}</h3>
          {status && status.title && (
            <Tag color={status?.color} label={status?.title}></Tag>
          )}
        </div>
        <i className="pi pi-cog" style={{ marginTop: 5 }}></i>
      </CardHeader>

      {url && status?.title === "running" && (
        <CardBody>
          <a
            className="app-external-link"
            href={url}
            target="_blank"
            rel="noreferrer"
          >
            <i className="pi pi-external-link"></i>
          </a>
        </CardBody>
      )}
    </Card>
  );
};

export default AppCard;

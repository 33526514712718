import { useTranslation } from "@dzangolab/react-i18n";
import { Page } from "@dzangolab/react-ui";

import WorkspaceForm from "../../components/Form/WorkspaceForm";

const AddWorkspace = () => {
  const { t } = useTranslation("workspaces");

  return (
    <Page title={t("new")}>
      <WorkspaceForm />
    </Page>
  );
};

export default AddWorkspace;

import { Provider, TextInput } from "@dzangolab/react-form";
import { useTranslation } from "@dzangolab/react-i18n";
import { Button } from "@dzangolab/react-ui";
import React from "react";
import { z } from "zod";

import "../tooltip.css";
interface Properties {
  onSave: (
    title: string,
    href: string,
    event?: React.MouseEvent<HTMLButtonElement>
  ) => void;
  defaultValues?: FormSchema;
}

interface FormSchema {
  title: string;
  src: string;
}

function useFormSchema() {
  let linkSchema = z.object({
    title: z.string().max(255).min(2),
    src: z.string().max(255).min(2),
  });

  return linkSchema;
}

export default function LinkToolbar({ onSave, defaultValues }: Properties) {
  const { t } = useTranslation("markdownEditor");

  const onSubmit = (formData: FormSchema) => {
    onSave(formData.title, formData.src);
  };

  return (
    <div className="input-wrapper">
      <Provider
        onSubmit={onSubmit}
        validationSchema={useFormSchema()}
        defaultValues={defaultValues}
        className="form-wrapper"
      >
        <TextInput name="title" placeholder={t("toolbar.linkTooltip.title")} />
        <TextInput name="src" placeholder={t("toolbar.linkTooltip.src")} />
        <Button type="submit" label={t("toolbar.linkTooltip.save")} />
      </Provider>
    </div>
  );
}

import baseApi, { TagsEnum } from "./base-api";

const releaseApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getWorkspaceUsers: build.query<any, { teamId: number }>({
      query: ({ teamId }) => {
        return {
          url: `/workspaces/${teamId}/users`,
        };
      },
      providesTags: [TagsEnum.WorkspaceUsers],
    }),
  }),
});

export const { useLazyGetWorkspaceUsersQuery } = releaseApi;

export default releaseApi;

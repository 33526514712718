import { useTranslation } from "@dzangolab/react-i18n";
import { Page } from "@dzangolab/react-ui";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { GlossaryForm } from "./components";
import { useCurrentWorkspace } from "../../hooks/UseCurrentWorkspace";
import { useLazyGetSupportedLocalesQuery } from "../../redux/apis/glossary";

const AddGlossary = () => {
  const navigate = useNavigate();

  const { t } = useTranslation("glossary");

  const { workspace: currentWorkspace } = useCurrentWorkspace();

  const onFormSubmitSuccess = () => {
    if (currentWorkspace && currentWorkspace.slug) {
      navigate(`/workspaces/${currentWorkspace.slug}/glossary`);
    }
  };

  const [
    fetchSupportedLocales,
    { data: supportedLocales, isLoading: isFetchingSupportedLocales },
  ] = useLazyGetSupportedLocalesQuery();

  useEffect(() => {
    fetchSupportedLocales();
  }, [fetchSupportedLocales]);

  return (
    <Page
      className="glossary-page"
      title={t("configure.title")}
      loading={isFetchingSupportedLocales}
    >
      {currentWorkspace && (
        <GlossaryForm
          onFormSubmitSuccess={onFormSubmitSuccess}
          workspaceId={currentWorkspace.id}
          supportedLocales={supportedLocales ?? []}
        />
      )}
    </Page>
  );
};

export default AddGlossary;

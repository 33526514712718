import { useTranslation } from "@dzangolab/react-i18n";
import { Button, Modal } from "@dzangolab/react-ui";
import { useState } from "react";

import InvitationForm from "./InvitationForm";

interface IProperties {
  onSubmitted: () => void;
}

export const InvitationModal: React.FC<IProperties> = ({ onSubmitted }) => {
  const [modalVisible, setModalVisible] = useState(false);
  const { t } = useTranslation("workspaceTeam");

  return (
    <div className="flex justify-content-center">
      <Button
        label={t("invite-user.button.label")}
        onClick={() => setModalVisible(true)}
      />
      <Modal
        className="workspace-user-invitation-modal"
        header={t("invite-user.form.title")}
        visible={modalVisible}
        onHide={() => setModalVisible(false)}
      >
        <InvitationForm
          onCancel={() => setModalVisible(false)}
          onSubmitted={onSubmitted}
        />
      </Modal>
    </div>
  );
};

export default InvitationModal;

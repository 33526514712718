import { State } from "@12degrees/stateful-core";

import baseApi, { TagsEnum } from "./base-api";

const releaseApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    addReleaseService: build.mutation<
      any,
      { releaseId: number; workspaceServiceId: number; releaseAppId: number }
    >({
      query: ({ releaseAppId, releaseId, workspaceServiceId }) => {
        return {
          url: `releases/${releaseId}/service`,
          method: "POST",
          body: {
            releaseAppId: releaseAppId,
            workspaceServiceId: workspaceServiceId,
          },
        };
      },
    }),
    getRelease: build.query<any, { workspaceId: number; releaseId: number }>({
      query: (input) => {
        return {
          url: `workspaces/${input.workspaceId}/releases/${input.releaseId}`,
        };
      },
      providesTags: [TagsEnum.Release],
    }),
    getReleaseStatuses: build.query<State[], void>({
      query: () => {
        return {
          url: `release/states`,
        };
      },
      providesTags: [TagsEnum.ReleaseStatuses],
    }),
    getReleaseBySlug: build.query<
      any,
      { workspaceId: number; releaseSlug: string }
    >({
      query: (input) => {
        return {
          url: `workspaces/${input.workspaceId}/releases/slug/${input.releaseSlug}`,
        };
      },
      providesTags: [TagsEnum.Release],
    }),
    unlinkService: build.mutation<
      any,
      { releaseId: number; workspaceServiceId: number; releaseAppId: number }
    >({
      query: ({ releaseAppId, releaseId, workspaceServiceId }) => {
        return {
          url: `releases/${releaseId}/services/unlink`,
          method: "DELETE",
          body: {
            releaseAppId,
            workspaceServiceId,
          },
        };
      },
    }),
    updateReleaseStatus: build.mutation<
      { releaseRecord: any },
      { releaseId: number; transitionId: number }
    >({
      query: ({ releaseId, transitionId }) => {
        return {
          url: `/releases/${releaseId}/transitions/${transitionId}`,
          method: "POST",
        };
      },
      invalidatesTags: [TagsEnum.ReleaseStatuses],
    }),
    deployRelease: build.query<any, { workspaceId: number; releaseId: number }>(
      {
        query: (input) => {
          return {
            url: `workspaces/${input.workspaceId}/releases/${input.releaseId}/deploy`,
          };
        },
        providesTags: [TagsEnum.Release],
      }
    ),
    stopRelease: build.query<any, { workspaceId: number; releaseId: number }>({
      query: (input) => {
        return {
          url: `workspaces/${input.workspaceId}/releases/${input.releaseId}/stop`,
        };
      },
      providesTags: [TagsEnum.Release],
    }),
    setupAndBuildRelease: build.query<
      any,
      { workspaceId: number; releaseId: number }
    >({
      query: (input) => {
        return {
          url: `workspaces/${input.workspaceId}/releases/${input.releaseId}/prepare`,
        };
      },
      providesTags: [TagsEnum.Release],
    }),
  }),
});

export const {
  useAddReleaseServiceMutation,
  useLazyGetReleaseQuery,
  useLazyGetReleaseBySlugQuery,
  useLazyGetReleaseStatusesQuery,
  useUpdateReleaseStatusMutation,
  useLazyDeployReleaseQuery,
  useLazyStopReleaseQuery,
  useLazySetupAndBuildReleaseQuery,
  useUnlinkServiceMutation,
} = releaseApi;

export default releaseApi;

import { useTranslation } from "@dzangolab/react-i18n";
import { Button } from "@dzangolab/react-ui";

type ActionsProperties = {
  disableStop?: boolean;
  disablePlay?: boolean;
  onStop: () => void;
  onPlay: () => void;
  onShare: () => void;
};

const Actions = ({
  disableStop,
  disablePlay,
  onStop,
  onPlay,
  onShare,
}: ActionsProperties) => {
  const { t } = useTranslation("release");

  const canPlay = disableStop;
  const actionIcon = canPlay ? "pi pi-caret-right" : "pi pi-stop";
  const isActionDisabled = disablePlay && disableStop;
  const label = canPlay ? t("play-button.label") : t("stop-button.label");
  const severity = canPlay ? "success" : "warning";

  const onClick = canPlay ? onPlay : onStop;

  const renderWorkspaceActionsMobile = () => {
    return (
      <div className="workspace-actions mobile">
        <Button
          variant="filled"
          severity="primary"
          iconLeft="pi pi-share-alt"
          rounded
          onClick={onShare}
        />
        <Button
          variant="filled"
          severity={severity}
          iconLeft={actionIcon}
          rounded
          disabled={isActionDisabled}
          onClick={onClick}
        />
      </div>
    );
  };

  const renderWorkspaceActionsDesktop = () => {
    return (
      <div className="workspace-actions desktop">
        <Button
          label={t("share-button.label")}
          variant="filled"
          severity="primary"
          onClick={onShare}
        />
        <Button
          label={label}
          variant="filled"
          severity={severity}
          disabled={isActionDisabled}
          onClick={onClick}
        />
      </div>
    );
  };

  return (
    <>
      {renderWorkspaceActionsMobile()}
      {renderWorkspaceActionsDesktop()}
    </>
  );
};

export default Actions;

import { Button } from "@dzangolab/react-ui";

type GlossaryLocalePickerProperties = {
  locales: string[];
  selectedLocale?: string;
  changeLocale: (locale: string) => void;
};

const GlossaryLocalePicker: React.FC<GlossaryLocalePickerProperties> = ({
  locales,
  selectedLocale,
  changeLocale,
}) => {
  return (
    <div className="locale-buttonset">
      {locales.length === 1 ? (
        <span className="code">{locales[0]}</span>
      ) : (
        locales.map((locale) => (
          <Button
            key={locale}
            label={locale}
            onClick={() => changeLocale(locale)}
            size="small"
            variant={selectedLocale !== locale ? "outlined" : "filled"}
          />
        ))
      )}
    </div>
  );
};

export default GlossaryLocalePicker;

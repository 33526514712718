import baseApi, { TagsEnum } from "./base-api";

const workspaceUsersApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    inviteWorkspaceUser: build.mutation<
      any,
      {
        workspaceId: number;
        data: {
          email: string;
          defaultReleaseRoleId: number;
        };
      }
    >({
      query: ({ workspaceId, data }) => {
        return {
          url: `/workspaces/${workspaceId}/users/invite`,
          method: "POST",
          body: data,
        };
      },
      invalidatesTags: (result, error, { workspaceId }) => [
        { type: TagsEnum.WorkspaceUsers, id: workspaceId },
      ],
    }),
  }),
});

export const { useInviteWorkspaceUserMutation } = workspaceUsersApi;

export default workspaceUsersApi;

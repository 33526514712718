import {
  TextInput,
  Textarea,
  useFormContext,
  useWatch,
} from "@dzangolab/react-form";
import { useTranslation } from "@dzangolab/react-i18n";
import { Button, SubmitButton } from "@dzangolab/react-ui";
import { Workspace } from "core";
import { useEffect } from "react";

type Properties = {
  handleCancel: () => void;
  isLoading: boolean;
  workspace?: Workspace;
  fieldErrors?: { [key: string]: string };
};

type FormFields = {
  description?: string;
  name: string;
  slug: string;
};

const WorkspaceFormFields = ({
  isLoading,
  workspace,
  handleCancel,
  fieldErrors,
}: Properties) => {
  const { t } = useTranslation("workspaceForm");

  const {
    setValue,
    setError,
    getFieldState,
    formState: { errors },
  } = useFormContext<FormFields>();

  const watchName: string = useWatch({ name: "name" });

  const getErrorMessage = (field: keyof FormFields): string => {
    return errors?.[field]?.message || "";
  };

  useEffect(() => {
    const { isDirty } = getFieldState("slug");

    if (!workspace && !isDirty) {
      const generatedSlug = watchName
        ?.trim()
        .toLowerCase()
        .replace(/[^a-z0-9]+/g, "-")
        .substring(0, 12)
        .replace(/^[-~!@#$%^&*()]+/, "")
        .replace(/[-~!@#$%^&*()]+$/, "");

      setValue("slug", generatedSlug);
    }
  }, [getFieldState, setValue, watchName, workspace]);

  useEffect(() => {
    if (fieldErrors?.slug) {
      setError("slug", {
        type: "manual",
        message: fieldErrors.slug,
      });
    }
  }, [fieldErrors, setError, watchName]);

  return (
    <>
      <TextInput
        label={t("form.fields.name")}
        name="name"
        errorMessage={getErrorMessage("name")}
      />
      {!workspace && (
        <TextInput
          label={t("form.fields.slug")}
          name="slug"
          errorMessage={getErrorMessage("slug")}
        />
      )}
      <Textarea
        label={t("form.fields.description")}
        name="description"
        errorMessage={getErrorMessage("description")}
      />
      <SubmitButton
        label={workspace ? t("form.actions.update") : t("form.actions.submit")}
        loading={isLoading}
      />
      {!workspace && (
        <Button
          label={t("form.actions.cancel")}
          type="button"
          onClick={handleCancel}
          severity="secondary"
          variant="outlined"
        />
      )}
    </>
  );
};

export default WorkspaceFormFields;

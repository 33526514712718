enum ROUTES {
  ABOUT = "/about",
  AUTH_GOOGLE = "/auth/callback/google",
  CHANGE_PASSWORD = "/change-password",
  CONFIGURE_APPS = "/workspaces/:id/configure",
  DOCS_PLAYGROUND = "/docs-playground",
  FORGOT_PASSWORD = "/forgot-password",
  HOME = "/",
  LOGIN = "/login",
  PROFILE = "/profile",
  PROFILE_COMPLETION = "/profile-completion",
  RESET_PASSWORD = "/reset-password",
  SERVICES = "/workspaces/:slug/services",
  SIGNUP = "/signup",
  SIGNUP_INVITED_USER = "/signup/token/:token",
  VERIFY_EMAIL = "/verify-email",
  VERIFY_EMAIL_REMINDER = "/verify-email-reminder",
  WORKSPACE_CREATE = "/workspaces/new",
  WORKSPACE_GLOSSARY = "/workspaces/:slug/glossary",
  WORKSPACE_GLOSSARY_START = "/workspaces/:slug/glossary/start",
  WORKSPACE_GLOSSARY_ADD_FIRST_TERM = "/workspaces/:slug/glossary/:id/add-first-term",
  WORKSPACE_GLOSSARY_NEW = "/workspaces/:slug/glossary/new",
  WORKSPACE_GLOSSARY_ENTRY_EDIT = "/workspaces/:slug/glossary/:glossaryId/entry/:entryId/edit",
  WORKSPACE_GLOSSARY_ENTRY_NEW = "/workspaces/:slug/glossary/:id/entry/new",
  WORKSPACE_DATA_MODEL = "/workspaces/:slug/releases/:releaseSlug/data-model",
  WORKSPACE_DOCS = "/workspaces/:slug/releases/:releaseSlug/docs",
  WORKSPACE_DOCUMENT = "/workspaces/:slug/releases/:releaseSlug/docs/*",
  WORKSPACE_DOCUMENT_TYPE_CREATE = "/workspaces/:id/docs/types/create",
  WORKSPACE_DOCUMENT_TYPE_EDIT = "/workspaces/:workspaceId/docs/types/:id/edit",
  WORKSPACE_OVERVIEW = "/workspaces/:slug/overview",
  WORKSPACE_RELEASE = "/workspaces/:slug/releases/:releaseSlug",
  WORKSPACE_RELEASES = "/workspaces/:slug/releases",
  WORKSPACE_REPOSITORIES = "/workspaces/:slug/repositories",
  WORKSPACE_REPOSITORY_CREATE = "/workspaces/:slug/repositories/new",
  WORKSPACE_REPOSITORY_EDIT = "/workspaces/:slug/repositories/:id/edit",
  WORKSPACE_SETTINGS_GENERAL = "/workspaces/:slug/settings/general",
  WORKSPACE_SETTINGS_GLOSSARY = "/workspaces/:slug/settings/glossary",
  WORKSPACE_SETTINGS_DOCUMENTATION = "/workspaces/:slug/settings/docs",
  WORKSPACE_TEAM = "/workspaces/:slug/team",
  WORKSPACES = "/workspaces",
}

enum APP_CONFIG_STEPS {
  "GENERAL" = "General",
  "APIS" = "APIs",
  "SERVICES" = "Services",
  "REACT_APP" = "React app",
  "REACT_ADMIN" = "React admin",
}

enum APP_TYPES {
  "REACT_ADMIN" = "reactAdmin",
  "REACT_APP" = "react",
  "FASTIFY_API" = "fastifyApi",
}

enum STATUS_TAG_COLOR {
  "RUNNING" = "green",
  "STOPPED" = "gray",
  "ERROR" = "red",
}

enum WORKSPACE_RELEASE_STATUS {
  "ERROR" = "error",
  "LOADING" = "loading",
  "RUNNING" = "running",
  "STOPPED" = "stopped",
  "READY" = "ready",
}

const RELEASE_SECTIONS = {
  APIS: "apis",
  APPS: "apps",
  SERVICES: "services",
};

const RELEASE_SERVICE_ALREADY_ADDED = "SERVICE_ALREADY_ADDED";

const DATABASE_SERVICE_TYPE = "Database";
const EMAIL_SERVICE_TYPE = "Email";
const FILE_STORAGE_SERVICE_TYPE = "File storage";
const SERVICE_TYPES = [
  DATABASE_SERVICE_TYPE,
  EMAIL_SERVICE_TYPE,
  FILE_STORAGE_SERVICE_TYPE,
];

const SUPPORTED_GLOSSARY_LOCALES = [
  {
    label: "English",
    value: "en",
  },
  {
    label: "French",
    value: "fr",
  },
  {
    label: "Spanish",
    value: "es",
  },
];

const RELEASE_STATUS_APPROVED = "approved";
const RELEASE_STATUS_CLOSED = "closed";

const AUTO_CLOSE_TOAST_DURATION_MS = 60000;

const EDGE_TYPES = {
  SERVICE_EDGE: "serviceEdge",
};

export {
  APP_CONFIG_STEPS,
  APP_TYPES,
  AUTO_CLOSE_TOAST_DURATION_MS,
  DATABASE_SERVICE_TYPE,
  EDGE_TYPES,
  EMAIL_SERVICE_TYPE,
  FILE_STORAGE_SERVICE_TYPE,
  RELEASE_SECTIONS,
  RELEASE_STATUS_APPROVED,
  RELEASE_STATUS_CLOSED,
  RELEASE_SERVICE_ALREADY_ADDED,
  ROUTES,
  SERVICE_TYPES,
  STATUS_TAG_COLOR,
  SUPPORTED_GLOSSARY_LOCALES,
  WORKSPACE_RELEASE_STATUS,
};

import { useTranslation } from "@dzangolab/react-i18n";
import { Button, ConfirmationModal } from "@dzangolab/react-ui";
import {
  BezierEdge,
  EdgeLabelRenderer,
  EdgeProps,
  getBezierPath,
  Handle,
  Position,
  ReactFlow,
} from "@xyflow/react";
import {
  getKeyFromValue,
  Release,
  ReleaseApp,
  Service,
  SERVICE_STATUS,
} from "core";
import { useState } from "react";
import { toast } from "react-toastify";

import { buildGraphNodesAndEdges } from "./utils";
import { AppCard } from "../../../components";
import {
  RELEASE_SECTIONS,
  STATUS_TAG_COLOR,
  WORKSPACE_RELEASE_STATUS,
} from "../../../constants";
import { useCurrentWorkspace } from "../../../hooks/UseCurrentWorkspace";
import { useUnlinkServiceMutation } from "../../../redux/apis/release";

import "@xyflow/react/dist/style.css";
import "./graph.css";

interface Properties {
  release: Release;
  onAddReleaseService: () => void;
}

const getReleaseTagColor = (status?: string | null) => {
  if (status === WORKSPACE_RELEASE_STATUS.STOPPED)
    return STATUS_TAG_COLOR.STOPPED;
  if (status === WORKSPACE_RELEASE_STATUS.ERROR) return STATUS_TAG_COLOR.ERROR;

  return STATUS_TAG_COLOR.RUNNING;
};

const renderAppCard = ({
  data,
}: {
  data: {
    app: ReleaseApp;
    releaseApps?: ReleaseApp[];
    releaseApis?: ReleaseApp[];
    releaseServices?: Service[];
  };
}) => {
  const { app, releaseApps, releaseApis, releaseServices } = data;

  if (!app) return null;

  const showTopHandle = app.type === "apis" && releaseApps?.length! > 0;
  const showBottomHandle =
    app.type === "apps"
      ? releaseApis?.length! > 0
      : app.type === "apis" && releaseServices?.length! > 0;

  return (
    <div className="node-wrapper">
      {showTopHandle && (
        <Handle isConnectable={false} type="target" position={Position.Top} />
      )}
      <AppCard
        name={app.name ?? ""}
        key={`app-${app.slug}`}
        url={app.url}
        status={{
          title: app.status,
          color: getReleaseTagColor(app.status),
        }}
      />
      {showBottomHandle && (
        <Handle
          isConnectable={false}
          type="source"
          position={Position.Bottom}
        />
      )}
    </div>
  );
};

const renderServiceCard = ({
  data,
}: {
  data: {
    service: Service;
    releaseApis?: ReleaseApp[];
  };
}) => {
  if (data.service) {
    return (
      <div className="node-wrapper">
        {data.releaseApis?.length! > 0 && (
          <Handle isConnectable={false} type="target" position={Position.Top} />
        )}
        <AppCard
          name={data.service.name ?? ""}
          key={`app-${data.service.slug}`}
          url={data.service.publicUrl}
          status={{
            title: data.service.statusId
              ? getKeyFromValue(SERVICE_STATUS, data.service.statusId)
              : "",
            color: getReleaseTagColor(
              getKeyFromValue(SERVICE_STATUS, data.service.statusId)
            ),
          }}
        />
      </div>
    );
  }

  return null;
};

const renderGroupWrapper = ({ data }: { data: Service }) => {
  return <div className="group-wrapper"></div>;
};

const ReleaseGraph: React.FC<Properties> = (properties) => {
  const { release, onAddReleaseService } = properties;
  const { t } = useTranslation("appOverview");

  const [selectedEdge, setSelectedEdge] = useState<EdgeProps>();

  const { refetchWorkspaceAndRelease } = useCurrentWorkspace();
  const [unlinkService] = useUnlinkServiceMutation();

  const nodesEdges = buildGraphNodesAndEdges({
    t: t,
    release: release,
  });

  const initialNodes = [...nodesEdges.nodes];
  const initialEdges = [...nodesEdges.edges];

  const onAccept = async () => {
    if (!selectedEdge?.data) return;

    const { releaseAppId, workspaceServiceId } = selectedEdge.data as {
      releaseAppId: number;
      workspaceServiceId: number;
    };

    const response = await unlinkService({
      releaseAppId,
      releaseId: release.id,
      workspaceServiceId,
    });

    if ("error" in response) {
      toast.error(t("release-service.unlink.message.error"));
    } else {
      toast.success(t("release-service.unlink.message.success"));

      refetchWorkspaceAndRelease();
    }
  };

  const Header = ({ data }: { data: { label: string; type: string } }) => {
    return (
      <div className="node-header">
        <h2 className="module-text">{data.label}</h2>

        {data.type === RELEASE_SECTIONS.SERVICES && (
          <Button
            iconLeft={"pi pi-plus"}
            variant="textOnly"
            onClick={onAddReleaseService}
          />
        )}
      </div>
    );
  };

  const ServiceEdge = (properties: EdgeProps) => {
    const {
      sourcePosition,
      sourceX,
      sourceY,
      targetPosition,
      targetX,
      targetY,
    } = properties;

    const [, labelX, labelY] = getBezierPath({
      sourcePosition,
      sourceX,
      sourceY,
      targetPosition,
      targetX,
      targetY,
    });

    return (
      <>
        <BezierEdge {...properties} />
        <EdgeLabelRenderer>
          <Button
            iconLeft="pi pi-times-circle"
            variant="textOnly"
            style={{
              pointerEvents: "all",
              position: "absolute",
              transform: `translate(-50%, -50%) translate(${labelX}px, ${labelY}px)`,
              zIndex: 100,
            }}
            onClick={() => setSelectedEdge(properties)}
          />
        </EdgeLabelRenderer>
      </>
    );
  };

  const nodeTypes = {
    appCard: renderAppCard,
    header: Header,
    serviceCard: renderServiceCard,
    groupWrapper: renderGroupWrapper,
  };

  const edgeTypes = {
    serviceEdge: ServiceEdge,
  };

  return (
    <>
      <ReactFlow
        nodes={initialNodes}
        nodesDraggable={false}
        edgesFocusable={false}
        nodeTypes={nodeTypes}
        edgeTypes={edgeTypes}
        edges={initialEdges}
        zoomOnDoubleClick={false}
        panOnDrag={false}
        zoomOnScroll={false}
        preventScrolling={false}
      />
      <ConfirmationModal
        accept={onAccept}
        header={t("release-service.unlink.confirmation.header")}
        message={t("release-service.unlink.confirmation.message", {
          serviceName: selectedEdge?.data?.workspaceServiceName,
        })}
        visible={!!selectedEdge}
        onHide={() => setSelectedEdge(undefined)}
      />
    </>
  );
};

export default ReleaseGraph;

import { Button } from "@dzangolab/react-ui";

import NameValuePairInput from "./NameValuePairInput";

interface Properties {
  addButtonLabel: string;
  fields: Record<"id", string>[];
  inputName: string;
  onAdd: () => void;
  onRemove: (index: number) => void;
  title: string;
}

const EnvironmentVariablesInput: React.FC<Properties> = ({
  addButtonLabel,
  fields,
  inputName,
  onAdd,
  onRemove,
  title,
}) => {
  return (
    <div className="environment-variables-section">
      <div className="title-wrapper">
        <p>{title}</p>
        <Button
          label={addButtonLabel}
          type="button"
          variant="outlined"
          onClick={onAdd}
        />
      </div>

      {fields.map((item, index) => (
        <NameValuePairInput
          key={item.id}
          fieldName={inputName}
          index={index}
          onRemove={() => onRemove(index)}
        />
      ))}
    </div>
  );
};

export default EnvironmentVariablesInput;

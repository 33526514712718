import { TextInput, useFormContext, useWatch } from "@dzangolab/react-form";
import { useTranslation } from "@dzangolab/react-i18n";
import { SubmitButton } from "@dzangolab/react-ui";
import { useEffect } from "react";

import MilkdownEditor from "../../../../../components/MilkdownEditor";

interface Properties {
  isSubmitting: boolean;
  fieldErrors?: { [key: string]: string };
  update?: boolean;
  template?: string;
  setTemplate?: React.Dispatch<React.SetStateAction<string | null>>;
}

const DocumentTypeFormFields: React.FC<Properties> = (properties) => {
  const { fieldErrors, isSubmitting, template, setTemplate, update } =
    properties;
  const { t } = useTranslation("docs");

  const { setValue, setError } = useFormContext();

  const watchName = useWatch({ name: "name" });

  useEffect(() => {
    setError("slug", {
      type: "manual",
      message: fieldErrors?.slug as string,
    });
  }, [fieldErrors, setError]);

  useEffect(() => {
    const generatedSlug = watchName
      ?.trim()
      .toLowerCase()
      .replace(/[^a-z0-9]+/g, "-");

    setValue(
      "slug",
      generatedSlug
        ?.replace(/^[-~!@#$%^&*()]+/, "")
        .replace(/[-~!@#$%^&*()]+$/, "")
    );
  }, [setValue, watchName]);

  return (
    <>
      <TextInput
        name="name"
        placeholder={t("docs-types.form.name.placeholder")}
        label={t("docs-types.form.name.label")}
      />
      <TextInput
        name="path"
        placeholder={t("docs-types.form.path.placeholder")}
        label={t("docs-types.form.path.label")}
      />
      {!update && (
        <TextInput
          name="slug"
          placeholder={t("docs-types.form.slug.placeholder")}
          label={t("docs-types.form.slug.label")}
        />
      )}

      <label htmlFor="template">{t("docs-types.form.template.label")}</label>
      <MilkdownEditor
        content={template}
        editable={true}
        setEditorContent={setTemplate}
      />

      <SubmitButton
        label={t("docs-types.actions.submit")}
        loading={isSubmitting}
        disabled={isSubmitting}
      />
    </>
  );
};

export default DocumentTypeFormFields;

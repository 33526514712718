import { useNavigate } from "react-router-dom";

import { useCurrentWorkspace } from "../../hooks/UseCurrentWorkspace";

const WorkspacesLayoutTitle = () => {
  const navigate = useNavigate();
  const { workspace: currentWorkspace } = useCurrentWorkspace();

  return (
    <div className="workspace-title-wrapper">
      <p className="workspace-title">{currentWorkspace?.name}</p>
      <i
        className="pi pi-angle-down workspace-selector"
        onClick={() => navigate("/workspaces")}
      ></i>
    </div>
  );
};

export default WorkspacesLayoutTitle;

import { StateTransition, State } from "@12degrees/stateful-core";
import { useTranslation } from "@dzangolab/react-i18n";
import { useEffect, useState } from "react";

import { ReleaseStatusWidget } from "../../../../components";

interface Properties {
  allStatuses?: State[];
  value?: number;
  onChange: (transitionId: number) => void;
  allowedTransitions: StateTransition[];
}

const ReleaseStatusSelector: React.FC<Properties> = (properties) => {
  const { t } = useTranslation("release");
  const { allStatuses, value, onChange, allowedTransitions } = properties;
  const [selectedValue, setSelectedValue] = useState<number | undefined>(value);

  useEffect(() => {
    setSelectedValue(value);
  }, [value]);

  return (
    <div className="release-selector">
      <label htmlFor="release-status-selector">
        {t("release-status.title")}
      </label>
      <ReleaseStatusWidget
        allowedTransitions={allowedTransitions}
        allStatuses={allStatuses ?? []}
        onReleaseChange={onChange}
        value={selectedValue}
      />
    </div>
  );
};

export default ReleaseStatusSelector;

import { useTranslation } from "@dzangolab/react-i18n";
import { Button, Page } from "@dzangolab/react-ui";
import { userContext } from "@dzangolab/react-user";
import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";

import MilkdownEditor from "../../components/MilkdownEditor";
import config from "../../config";
import { useLocalStorage } from "../../hooks";
import { useCurrentWorkspace } from "../../hooks/UseCurrentWorkspace";
import {
  useLazyGetDocumentQuery,
  useUpdateDocumentMutation,
} from "../../redux/apis/document";

const Document = () => {
  const parameters = useParams();
  const { t } = useTranslation("docs");
  const documentPath = parameters["*"] as string;

  const [editMode, setEditMode] = useState(false);
  const [fetchDocument, { data: document, isFetching: isFetchingDocument }] =
    useLazyGetDocumentQuery();
  const [selectedRelease] = useLocalStorage<any>("selectedRelease", null);

  const { workspace: currentWorkspace } = useCurrentWorkspace();

  const [updateDocument] = useUpdateDocumentMutation();

  useEffect(() => {
    if (documentPath && currentWorkspace && selectedRelease) {
      if (selectedRelease.id) {
        fetchDocument({
          workspaceId: currentWorkspace.id,
          path: documentPath,
          releaseId: selectedRelease.id,
        });
      }
    }
  }, [documentPath, fetchDocument, currentWorkspace, selectedRelease]);

  const context = useContext(userContext);
  const userName = context?.user?.givenName || context?.user?.id || "Anon";
  const websocketUrl = config.apiBaseUrl;

  const handleUpdateDocument = async (content: string) => {
    if (currentWorkspace && documentPath && selectedRelease) {
      const response = await updateDocument({
        workspaceId: currentWorkspace.id,
        path: documentPath,
        updatedContent: content,
        releaseId: selectedRelease.id,
      });

      if ("error" in response) {
        toast.error(t("add-docs.message.error.docs-update-failed"));
      } else {
        toast.success(t("add-docs.message.success.docs-updated"));
      }
    }
  };

  return (
    <Page
      title={"Document"}
      loading={isFetchingDocument}
      toolbar={
        !editMode && (
          <Button
            label={t("edit-document.title", { ns: "docs" })}
            size="small"
            onClick={() => setEditMode(true)}
          />
        )
      }
    >
      {document && (
        <section className="document-editor-wrapper">
          <MilkdownEditor
            content={document.content}
            editable={editMode}
            onCollab={{
              userName,
              roomId: documentPath,
              websocketUrl,
              onDocumentSave: handleUpdateDocument,
            }}
          />
        </section>
      )}
    </Page>
  );
};

export default Document;

import { useTranslation } from "@dzangolab/react-i18n";
import { Page } from "@dzangolab/react-ui";
import * as Sentry from "@sentry/react";
import { useEffect } from "react";
import { useRouteError } from "react-router-dom";
import "./ErrorBoundary.css";

type RouteError = {
  statusText?: string;
  message?: string;
};

const NotFound = (): JSX.Element => {
  const { t } = useTranslation();
  const error = useRouteError() as RouteError;

  useEffect(() => {
    Sentry.captureException(error);
  }, [error]);

  return (
    <Page>
      <div className="error-page">
        <h1>{t("errorBoundary.title")}</h1>
        <p>{t("errorBoundary.subtitle")}</p>
        <p>
          <i>{error.statusText || error.message}</i>
        </p>
      </div>
    </Page>
  );
};

export default NotFound;

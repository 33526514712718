import { Provider } from "@dzangolab/react-form";
import { useTranslation } from "@dzangolab/react-i18n";
import { toast } from "react-toastify";
import { z } from "zod";

import InvitationFormFields from "./InvitationFormFields";
import { useCurrentWorkspace } from "../../../../../hooks/UseCurrentWorkspace";
import { useInviteWorkspaceUserMutation } from "../../../../../redux/apis/workspace-users";

interface IProperties {
  onCancel: () => void;
  onSubmitted: () => void;
}

const InvitationForm: React.FC<IProperties> = ({ onCancel, onSubmitted }) => {
  const { t } = useTranslation("workspaceTeam");
  const { workspace: currentWorkspace } = useCurrentWorkspace();
  const [inviteUser, { isLoading }] = useInviteWorkspaceUserMutation();

  const invitationFormValidationSchema = z.object({
    email: z.string().email(),
    defaultReleaseRoleId: z.number({
      message: t(
        "invite-user.form.fields.default-release-role.message.required"
      ),
    }),
  });

  const handleSubmit = async (data: {
    email: string;
    defaultReleaseRoleId: number;
  }) => {
    if (!currentWorkspace) {
      return;
    }

    const response = await inviteUser({
      workspaceId: currentWorkspace.id,
      data,
    });

    if ("error" in response) {
      toast.error((response.error as any).data?.message);
    } else {
      toast.success(t("invite-user.form.messages.success"));

      onSubmitted();
      onCancel();
    }
  };

  return (
    <Provider
      onSubmit={handleSubmit}
      defaultValues={{ email: "", defaultReleaseRoleId: null }}
      validationSchema={invitationFormValidationSchema}
    >
      <InvitationFormFields isLoading={isLoading} onCancel={onCancel} />
    </Provider>
  );
};

export default InvitationForm;

import { useTranslation } from "@dzangolab/react-i18n";
import { Button, ConfirmationModal, Tag, Message } from "@dzangolab/react-ui";
import { Glossary, GlossaryLocales, SupportedLocales } from "core";
import { useCallback, useEffect, useState } from "react";
import { toast } from "react-toastify";

import SelectLocaleDialog from "./SelectLocaleDialog";
import { useUpdateGlossaryMutation } from "../../../../../redux/apis/glossary";

interface Properties {
  glossary: Glossary;
  supportedLocales: SupportedLocales[];
}

const GlossaryLocalesList: React.FC<Properties> = (properties) => {
  const { glossary, supportedLocales } = properties;
  const { t } = useTranslation("glossary");
  const [selectLocaleDialogVisible, setSelectLocaleDialogVisible] =
    useState(false);
  const [showMainLocaleRequiredError, setShowMainLocaleRequiredError] =
    useState(false);
  const [updatedLocales, setUpdatedLocales] = useState<GlossaryLocales[]>(
    glossary.locales
  );
  const [unUsedLocales, setUnUsedLocales] = useState<SupportedLocales[]>([]);
  const [localeToDelete, setLocaleToDelete] = useState<GlossaryLocales | null>(
    null
  );

  const [updateGlossary] = useUpdateGlossaryMutation();

  const onNewLocaleSelected = (locale: string, mainLocale: boolean) => {
    const selectedLocale = supportedLocales.find(
      (supportedLocale) => supportedLocale.localeCode === locale
    );

    if (selectedLocale) {
      if (mainLocale) {
        setUpdatedLocales((oldState) =>
          oldState.map((oldLocale) => {
            return {
              ...oldLocale,
              mainLocale: false,
            };
          })
        );
      }

      setUpdatedLocales((oldState) => [
        ...oldState,
        {
          ...selectedLocale,
          mainLocale: mainLocale,
        },
      ]);
    }
  };

  const onLocaleRemoved = () => {
    setUpdatedLocales((oldState) =>
      oldState.filter(
        (oldLocale) => oldLocale.localeCode !== localeToDelete?.localeCode
      )
    );

    toast.success(
      t("glossary-settings.delete.message.success", {
        locale: localeToDelete?.name,
      })
    );

    setLocaleToDelete(null);
  };

  const saveSettings = useCallback(async () => {
    setShowMainLocaleRequiredError(false);

    const mainLocale = updatedLocales.find((locale) => locale.mainLocale);

    if (!mainLocale) {
      setShowMainLocaleRequiredError(true);

      return;
    }

    const nonMainLocales = updatedLocales.filter(
      (locale) => !locale.mainLocale
    );

    let response = await updateGlossary({
      glossaryId: glossary.id,
      data: {
        i18n: glossary.i18n,
        mainLocale: mainLocale?.localeCode,
        supportedLocales: nonMainLocales.map((locale) => locale.localeCode),
      },
    });

    if ("error" in response) {
      toast.error("An error occurred while updating the glossary settings.");
    }
  }, [glossary.i18n, glossary.id, updateGlossary, updatedLocales]);

  useEffect(() => {
    setUnUsedLocales(
      supportedLocales.filter(
        (supportedLocale) =>
          !updatedLocales.find(
            (updatedLocale) =>
              updatedLocale.localeCode === supportedLocale.localeCode
          )
      )
    );
  }, [supportedLocales, updatedLocales]);

  useEffect(() => {
    saveSettings();
  }, [saveSettings, updatedLocales]);

  return (
    <div className="settings-glossary-list">
      <div className="header">
        <h2>{t("locales")}</h2>
      </div>

      {showMainLocaleRequiredError && (
        <Message
          message={t("glossary-settings.messages.error.missing-main-locale")}
        />
      )}

      <div className="locale-list">
        {updatedLocales.map((locale) => {
          return (
            <div key={`${locale.id}`} className="locale-item">
              <span className="code">{locale.localeCode}</span>
              <span className="name">
                {locale.name}
                {locale.mainLocale && <Tag label="Main locale" />}
              </span>
              {!locale.mainLocale && (
                <div className="action">
                  <Button
                    iconLeft="pi pi-trash"
                    onClick={() => setLocaleToDelete(locale)}
                    severity={"danger"}
                    variant="textOnly"
                  />
                </div>
              )}
            </div>
          );
        })}
      </div>
      <Button
        label={t("glossary-settings.actions.add-locale")}
        variant="filled"
        onClick={() => setSelectLocaleDialogVisible(true)}
        disabled={supportedLocales.length === updatedLocales.length}
      />
      <SelectLocaleDialog
        onHide={() => setSelectLocaleDialogVisible(false)}
        visible={selectLocaleDialogVisible}
        supportedLocales={unUsedLocales}
        onLocaleSelected={onNewLocaleSelected}
      />
      <ConfirmationModal
        onHide={() => setLocaleToDelete(null)}
        visible={localeToDelete !== null}
        header={t("glossary-settings.delete.modal.title")}
        message={t("glossary-settings.delete.modal.message", {
          locale: localeToDelete?.name,
        })}
        acceptButtonOptions={{ severity: "danger" }}
        accept={() => onLocaleRemoved()}
        reject={() => setLocaleToDelete(null)}
      />
    </div>
  );
};

export default GlossaryLocalesList;

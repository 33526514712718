import { useTranslation } from "@dzangolab/react-i18n";
import {
  Page,
  TableColumnDefinition,
  TDataTable as DataTable,
  Tag,
} from "@dzangolab/react-ui";
import { useEffect } from "react";

import { InvitationModal } from "./components";
import { useCurrentWorkspace } from "../../hooks/UseCurrentWorkspace";
import { useLazyGetWorkspaceUsersQuery } from "../../redux/apis/teams";

const Team = () => {
  const { t } = useTranslation("workspaceTeam");

  const { workspace: currentWorkspace } = useCurrentWorkspace();

  const [
    getWorkspaceUsers,
    { data: workspaceUsers, isFetching: isFetchingWorkspaceUsers },
  ] = useLazyGetWorkspaceUsersQuery();

  useEffect(() => {
    if (currentWorkspace) {
      getWorkspaceUsers({ teamId: currentWorkspace.id });
    }
  }, [currentWorkspace, getWorkspaceUsers]);

  const columns: TableColumnDefinition<any>[] = [
    {
      accessorKey: "email",
      header: t("table.columns.email"),
      enableSorting: true,
    },
    {
      accessorKey: "name",
      header: t("table.columns.name"),
      enableSorting: true,
      width: "25rem",
      minWidth: "25rem",
      maxWidth: "25rem",
      cell: ({ row: { original } }: { row: { original: any } }) => {
        return (
          (original.givenName ? original.givenName : "") +
            (original.middleNames ? " " + original.middleNames : "") +
            (original.surname ? " " + original.surname : "") || "-"
        );
      },
    },
    {
      accessorKey: "roles",
      header: t("table.columns.role"),
      enableSorting: true,
      width: "10rem",
      minWidth: "10rem",
      maxWidth: "10rem",
      cell: ({ row: { original } }: { row: { original: any } }) => {
        return currentWorkspace && currentWorkspace.ownerId === original.id ? (
          <Tag label={t("owner")} />
        ) : (
          <></>
        );
      },
    },
  ];

  const getToolbar = () => {
    return (
      <>
        <InvitationModal
          onSubmitted={() => getWorkspaceUsers({ teamId: currentWorkspace.id })}
        />
      </>
    );
  };

  return (
    <Page
      title={t("title")}
      loading={isFetchingWorkspaceUsers}
      toolbar={getToolbar()}
    >
      {workspaceUsers && (
        <DataTable
          className="workspace-users-table"
          columns={columns}
          data={workspaceUsers}
          isLoading={isFetchingWorkspaceUsers}
          emptyTableMessage={t("table.empty")}
        />
      )}
    </Page>
  );
};

export default Team;

import {
  DirectoryTreeNode,
  DocumentType,
  DocumentTypeCreateInput,
  DocumentTypeUpdateInput,
} from "core";
import { DocumentCreateInput } from "core/src/types";

import baseApi, { TagsEnum } from "./base-api";

const docsApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    createDocument: build.mutation<
      any,
      { workspaceId: number; releaseId: number; data: DocumentCreateInput }
    >({
      query: ({ workspaceId, releaseId, data }) => {
        return {
          url: `workspace/${workspaceId}/releases/${releaseId}/documents`,
          method: "POST",
          body: data,
        };
      },
      invalidatesTags: [TagsEnum.Document],
    }),

    createDocumentType: build.mutation<
      any,
      { workspaceId: number; data: DocumentTypeCreateInput }
    >({
      query: ({ workspaceId, data }) => {
        return {
          url: `/workspace/${workspaceId}/documents/types`,
          method: "POST",
          body: data,
        };
      },
      invalidatesTags: [TagsEnum.DocumentType],
    }),

    updateDocumentType: build.mutation<
      any,
      { workspaceId: number; data: DocumentTypeUpdateInput; id: number }
    >({
      query: ({ workspaceId, data, id }) => {
        return {
          url: `/workspace/${workspaceId}/documents/types/${id}`,
          method: "PUT",
          body: data,
        };
      },
      invalidatesTags: [TagsEnum.DocumentType],
    }),
    getDocumentTree: build.query<
      DirectoryTreeNode[],
      { workspaceId: number; releaseId?: number; branch?: string }
    >({
      query: (input) => {
        return {
          url: `workspace/${input.workspaceId}/documents/tree`,
          method: "POST",
          body: {
            releaseId: input.releaseId,
            branch: input.branch,
          },
        };
      },
      providesTags: [TagsEnum.Documents],
    }),
    getDocumentTypes: build.query<DocumentType[], number>({
      query: (workspaceId: number) => {
        return {
          url: `workspace/${workspaceId}/documents/types`,
        };
      },
      providesTags: [TagsEnum.DocumentTypes],
    }),

    getDocumentType: build.query<
      DocumentType,
      { workspaceId: number; id: number }
    >({
      query: ({ workspaceId, id }) => {
        return {
          url: `workspace/${workspaceId}/documents/types/${id}`,
        };
      },
      providesTags: [TagsEnum.DocumentType],
    }),

    getDocument: build.query<
      any,
      { workspaceId: number; path: string; releaseId: number }
    >({
      query: (input) => {
        return {
          url: `workspace/${input.workspaceId}/releases/${input.releaseId}/documents${input.path}`,
        };
      },
      providesTags: [TagsEnum.Document],
    }),

    updateDocument: build.mutation<
      any,
      {
        workspaceId: number;
        releaseId: number;
        path: string;
        updatedContent: string;
      }
    >({
      query: ({ workspaceId, path, updatedContent, releaseId }) => {
        return {
          url: `workspace/${workspaceId}/releases/${releaseId}/documents/${path}`,
          method: "PUT",
          body: {
            updatedContent,
          },
        };
      },
      invalidatesTags: [TagsEnum.Document],
    }),
  }),
});

export const {
  useCreateDocumentMutation,
  useUpdateDocumentMutation,
  useCreateDocumentTypeMutation,
  useUpdateDocumentTypeMutation,
  useLazyGetDocumentQuery,
  useLazyGetDocumentTreeQuery,
  useLazyGetDocumentTypesQuery,
  useLazyGetDocumentTypeQuery,
} = docsApi;

export default docsApi;

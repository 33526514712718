import { useTranslation } from "@dzangolab/react-i18n";
import { UserEnabledSidebarHeaderLayout } from "@dzangolab/react-user";
import { useDispatch } from "react-redux";
import { Outlet } from "react-router-dom";

import { ROUTES } from "../constants";
import baseApi from "../redux/apis/base-api";
import { resetSelectedWorkspaces } from "../redux/SelectedWorkspacesSlice";
import { WebSocketProvider } from "../WebSocketContext";

const BasicLayout = ({
  displayHeader = true,
  headerTitle,
  isUser,
}: {
  displayHeader?: boolean;
  headerTitle?: string;
  isUser?: boolean;
}): JSX.Element => {
  const { t } = useTranslation("app");
  const dispatch = useDispatch();

  const userMenu = generateUserMenu(t, isUser);

  return (
    <WebSocketProvider>
      <UserEnabledSidebarHeaderLayout
        className="basic-layout"
        noLocaleSwitcher={false}
        children={<Outlet />}
        userNavigationMenu={{
          id: "user-menu",
          label: t("user:userMenu.label"),
          menu: userMenu,
        }}
        displayNavIcons={true}
        onLogout={async () => handleLogout(dispatch)}
      />
    </WebSocketProvider>
  );
};

const generateUserMenu = (t: (key: string) => string, isUser?: boolean) => {
  if (!isUser) return [];

  return [
    {
      icon: "pi pi-lock",
      label: t("user:changePassword.title"),
      route: ROUTES.CHANGE_PASSWORD,
    },
    {
      icon: "pi pi-user",
      label: t("user:userMenu.profile"),
      route: ROUTES.PROFILE,
    },
  ];
};

const handleLogout = (dispatch: any) => {
  dispatch(resetSelectedWorkspaces());
  dispatch(baseApi.util.resetApiState());
};

export default BasicLayout;

import { useTranslation } from "@dzangolab/react-i18n";
import { Button, Page } from "@dzangolab/react-ui";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { DocumentTypesTable } from "./components";
import { useCurrentWorkspace } from "../../../hooks/UseCurrentWorkspace";
import { useLazyGetDocumentTypesQuery } from "../../../redux/apis/document";

const DocumentationSettings = () => {
  const { t } = useTranslation("docs");
  const navigate = useNavigate();
  const [
    getDocumentTypes,
    { data: documentTypes, isFetching: isFetchingGetDocumentTypes },
  ] = useLazyGetDocumentTypesQuery();

  const { workspace: currentWorkspace } = useCurrentWorkspace();

  useEffect(() => {
    if (currentWorkspace) {
      getDocumentTypes(currentWorkspace.id);
    }
  }, [currentWorkspace, getDocumentTypes]);

  return (
    <Page
      className="docs-settings-page"
      title={t("docs-settings.title")}
      loading={isFetchingGetDocumentTypes}
    >
      {currentWorkspace && documentTypes && (
        <>
          <div className="add-doc-type">
            <Button
              label={t("docs-types.actions.add")}
              variant="outlined"
              iconLeft="pi pi-plus"
              onClick={() =>
                navigate(`/workspaces/${currentWorkspace.id}/docs/types/create`)
              }
            />
          </div>
          <h2>{t("docs-types.title")}</h2>

          <DocumentTypesTable
            loading={isFetchingGetDocumentTypes}
            documentTypes={documentTypes}
          />
        </>
      )}
    </Page>
  );
};

export default DocumentationSettings;

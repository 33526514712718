import { Select, useFormContext } from "@dzangolab/react-form";
import { useTranslation } from "@dzangolab/react-i18n";
import { SubmitButton } from "@dzangolab/react-ui";
import { Release, Service } from "core";

interface Properties {
  isSubmitting?: boolean;
  releaseApps: Release["apps"];
  workspaceServices: Service[];
}

const AddReleaseServiceFormFields: React.FC<Properties> = (properties) => {
  const { isSubmitting, releaseApps, workspaceServices } = properties;
  const { t } = useTranslation("appOverview");

  const {
    formState: { errors },
  } = useFormContext();

  return (
    <>
      <Select
        autoSelectSingleOption
        name="releaseAppId"
        label={t("release-service.add.form.releaseApp.label")}
        placeholder={t("release-service.add.form.releaseApp.placeholder")}
        options={releaseApps?.map((app) => ({
          label: app.name,
          value: app.id,
        }))}
      />
      <Select
        autoSelectSingleOption
        name="workspaceServiceId"
        label={t("release-service.add.form.service.label")}
        placeholder={t("release-service.add.form.service.placeholder")}
        options={workspaceServices?.map((service) => ({
          label: service.name,
          value: service.id,
        }))}
      />
      <SubmitButton
        label={t("release-service.add.form.submit.label")}
        disabled={Object.keys(errors).length > 0 || isSubmitting}
        loading={isSubmitting}
      />
    </>
  );
};

export default AddReleaseServiceFormFields;

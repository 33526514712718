import { useTranslation } from "@dzangolab/react-i18n";
import { Button, Page } from "@dzangolab/react-ui";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import { useCurrentWorkspace } from "../../hooks/UseCurrentWorkspace";
import { useCreateGlossaryMutation } from "../../redux/apis/glossary";

const StartGlossaryPage = () => {
  const { t } = useTranslation("glossary");
  const navigate = useNavigate();

  const { workspace: currentWorkspace } = useCurrentWorkspace();

  const [createGlossary, { isLoading: isCreatingGlossary }] =
    useCreateGlossaryMutation();

  const handleStart = async () => {
    let response = await createGlossary({
      workspaceId: currentWorkspace.id,
      data: {
        i18n: [
          {
            name: `${currentWorkspace.name}'s glossary` ?? "Glossary",
            description: `Glossary for ${currentWorkspace.name ?? "Workspace"}`,
            locale: "en",
          },
        ],
      },
    });

    if ("error" in response) {
      toast.error(t("message.error.default"));
    } else {
      toast.success(t("message.success"));
      navigate(`/workspaces/${currentWorkspace.slug}/settings/glossary`);
    }
  };

  return (
    <Page className="start-glossary-page" title={t("title")}>
      <div className="center">
        <div className="container">
          <p>{t("pages.start-glossary.title")}</p>
          <Button
            label={t("pages.start-glossary.actions.start")}
            variant="filled"
            onClick={handleStart}
            disabled={isCreatingGlossary}
          />
        </div>
      </div>
    </Page>
  );
};

export default StartGlossaryPage;

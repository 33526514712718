import { ToastContainer } from "react-toastify";

import Routers from "./Routers";

function App() {
  return (
    <>
      <Routers />
      <ToastContainer position="top-center" stacked theme="colored" />
    </>
  );
}

export default App;

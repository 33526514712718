import { useTranslation } from "@dzangolab/react-i18n";
import { Page } from "@dzangolab/react-ui";

import BaseWorkspaceForm from "../../components/Form/WorkspaceForm";
import { useCurrentWorkspace } from "../../hooks/UseCurrentWorkspace";

const GeneralSettings = () => {
  const { t } = useTranslation("workspaceForm");
  const { workspace: currentWorkspace } = useCurrentWorkspace();

  return (
    <Page className="settings-page" title={t("editWorkspaceTitle")}>
      <BaseWorkspaceForm workspace={currentWorkspace} />
    </Page>
  );
};

export default GeneralSettings;

import { useTranslation } from "@dzangolab/react-i18n";
import { Page } from "@dzangolab/react-ui";

import { RepositoryForm } from "./components";

const AddRepository = () => {
  const { t } = useTranslation("repositories");

  return (
    <Page title={t("title.new")}>
      <RepositoryForm />
    </Page>
  );
};

export default AddRepository;

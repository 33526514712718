import app from "./app.json";
import appOverview from "./appOverview.json";
import auth from "./auth.json";
import docs from "./docs.json";
import errors from "./errors.json";
import glossary from "./glossary.json";
import invitations from "./invitations.json";
import locales from "./locales.json";
import markdownEditor from "./markdownEditor.json";
import release from "./release.json";
import repositories from "./repositories.json";
import services from "./services.json";
import user from "./user.json";
import workspaceDataModel from "./workspaceDataModel.json";
import workspaceForm from "./workspaceForm.json";
import workspaces from "./workspaces.json";
import workspaceTeam from "./workspaceTeam.json";

const en = {
  app,
  appOverview,
  auth,
  docs,
  errors,
  glossary,
  invitations,
  locales,
  markdownEditor,
  release,
  repositories,
  services,
  user,
  workspaceDataModel,
  workspaceForm,
  workspaces,
  workspaceTeam,
};

export default en;

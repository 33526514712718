/* eslint-disable no-console */
class WebSocketManager {
  private static instance: WebSocketManager;
  private socket: WebSocket | null = null;
  private reconnectInterval: number = 1000;
  private manualClose: boolean = false;
  private url: string = "";
  private room: string = "";

  private constructor() {}

  public static getInstance(): WebSocketManager {
    if (!WebSocketManager.instance) {
      WebSocketManager.instance = new WebSocketManager();
    }

    return WebSocketManager.instance;
  }

  public connect(url: string, room: string): WebSocket {
    if (this.socket && this.socket.readyState === WebSocket.OPEN) {
      return this.socket;
    }

    this.url = url;
    this.room = room;

    this.socket = new WebSocket(`${url}/releases?room=${room}`);
    this.manualClose = false;

    this.socket.onopen = () => {
      console.log("WebSocket connection opened");
      this.reconnectInterval = 1000;
    };

    this.socket.onclose = () => {
      console.log("WebSocket connection closed");

      if (!this.manualClose) {
        this.handleReconnect();
      }
    };

    this.socket.onerror = (error) => {
      console.error("WebSocket error", error);

      this.socket?.close();
    };

    return this.socket;
  }

  private handleReconnect() {
    if (!this.url || !this.room) {
      console.log("URL or room is missing. Closing WebSocket.");

      if (this.socket && !this.manualClose) {
        this.manualClose = true;
        this.socket.close();
        this.socket = null;
      }

      return;
    }

    if (this.reconnectInterval < 30000) {
      setTimeout(() => {
        console.log("Attempting to reconnect...");

        // Reconnect using stored URL and room
        this.connect(this.url, this.room);
        this.reconnectInterval *= 2;
      }, this.reconnectInterval);
    }
  }

  public getSocket(): WebSocket | null {
    return this.socket;
  }

  public close() {
    if (this.socket) {
      this.manualClose = true;

      this.socket.close();
      this.socket = null;
    }
  }
}

export default WebSocketManager;

import { useTranslation } from "@dzangolab/react-i18n";
import { Page } from "@dzangolab/react-ui";
import { Repository } from "core";
import { useEffect } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";

import { RepositoryForm } from "./components";
import { useCurrentWorkspace } from "../../hooks/UseCurrentWorkspace";
import { useLazyGetRepositoryQuery } from "../../redux/apis/repositories";

const EditRepository = () => {
  const { t } = useTranslation("repositories");
  const location = useLocation();
  const navigate = useNavigate();
  const { id: repositoryId } = useParams<{ id: string }>();

  const { workspace } = useCurrentWorkspace();
  const repository = location.state as Repository | undefined;

  const [fetchRepository, { data, error, isFetching }] =
    useLazyGetRepositoryQuery();

  useEffect(() => {
    if (workspace && !repository && repositoryId) {
      fetchRepository({
        workspaceId: workspace.id,
        id: parseInt(repositoryId),
      });
    }
  }, [workspace, repository, repositoryId, fetchRepository]);

  useEffect(() => {
    if (workspace && error && "status" in error && error.status === 404) {
      toast.error(t("messages.noAccess"));
      navigate(`/workspaces/${workspace.slug}/repositories`);
    }
  }, [error, workspace, navigate, t]);

  return (
    <Page title={t("title.edit")} loading={isFetching}>
      <RepositoryForm repository={repository || data} />
    </Page>
  );
};

export default EditRepository;

import { editorViewCtx, serializerCtx, Editor } from "@milkdown/core";
import { Ctx } from "@milkdown/ctx";

export const getMarkdown = (get: () => Editor | undefined) => {
  let editor = get();

  return editor!.action((context: Ctx) => {
    const editorView = context.get(editorViewCtx);
    const serializer = context.get(serializerCtx);

    return serializer(editorView.state.doc);
  });
};

import { useTranslation } from "@dzangolab/react-i18n";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Tooltip,
} from "@dzangolab/react-ui";
import { ReleaseApp, Repository } from "core";
import { useRef } from "react";
import { useNavigate } from "react-router-dom";

import { useCurrentWorkspace } from "../../../hooks/UseCurrentWorkspace";

interface Properties {
  repository: Repository & { apps?: ReleaseApp[] };
  onDelete: (repository: Repository) => void;
}

const RepositoryCard: React.FC<Properties> = ({ repository, onDelete }) => {
  const { t } = useTranslation("repositories");
  const { id, name, url } = repository;

  const navigate = useNavigate();
  const { workspace } = useCurrentWorkspace();

  const tooltipReference = useRef(null);
  const onEdit = () => {
    navigate(`/workspaces/${workspace.slug}/repositories/${id}/edit`, {
      state: repository,
    });
  };

  const renderHeader = () => {
    return (
      <>
        <h2>{name}</h2>
        <div className="card-action">
          {repository.apps?.length! > 0 && (
            <Tooltip elementRef={tooltipReference} position="top" offset={10}>
              <span>{t("messages.delete.disable")}</span>
            </Tooltip>
          )}
          <span ref={tooltipReference}>
            <Button
              disabled={repository.apps?.length! > 0}
              variant="textOnly"
              iconLeft="pi pi-trash"
              severity={"danger"}
              onClick={() => onDelete(repository)}
            />
          </span>

          <Button variant="textOnly" iconLeft="pi pi-pencil" onClick={onEdit} />
        </div>
      </>
    );
  };

  return (
    <Card className="repository-card">
      <CardHeader>{renderHeader()}</CardHeader>
      <CardBody>
        <p>{url}</p>
      </CardBody>
    </Card>
  );
};

export default RepositoryCard;

import { useTranslation } from "@dzangolab/react-i18n";
import { Page } from "@dzangolab/react-ui";
import { useParams } from "react-router-dom";

import DocumentTypeForm from "../components/DocumentTypeForm";

const CreateDocumentTypes: React.FC = () => {
  const { t } = useTranslation("docs");
  const { id } = useParams();

  return (
    <Page title={t("docs-types.actions.add-docs-type")}>
      <DocumentTypeForm workspaceId={Number(id!)} />
    </Page>
  );
};

export default CreateDocumentTypes;

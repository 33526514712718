import { useTranslation } from "@dzangolab/react-i18n";
import { Button, Page } from "@dzangolab/react-ui";
import { useEffect, useState } from "react";

import { AddDocsDialog, DocumentTree } from "./components";
import ReleasePageTitle from "../../components/common/ReleasePageTitle";
import { useLocalStorage } from "../../hooks";
import { useCurrentWorkspace } from "../../hooks/UseCurrentWorkspace";
import {
  useLazyGetDocumentTreeQuery,
  useLazyGetDocumentTypesQuery,
} from "../../redux/apis/document";

const Docs = () => {
  const { t } = useTranslation("docs");
  const [targetDestination, setTargetDestination] = useState<string>("");
  const [isAddDocsDialogVisible, setIsAddDocsDialogVisible] = useState(false);

  const [
    fetchDocumentTypes,
    { data: documentTypes, isFetching: isFetchingGetDocumentTypes },
  ] = useLazyGetDocumentTypesQuery();
  const [
    fetchDocumentTree,
    { data: documentTree, isFetching: isLoadingDocumentTree },
  ] = useLazyGetDocumentTreeQuery();

  const { workspace: currentWorkspace } = useCurrentWorkspace();
  const [selectedRelease] = useLocalStorage<any>("selectedRelease", null);

  const onAddDocsClick = (destination?: string) => {
    if (destination) {
      setTargetDestination(destination);
    }

    setIsAddDocsDialogVisible(true);
  };

  const onDocumentAdded = () => {
    if (currentWorkspace && selectedRelease) {
      fetchDocumentTree({
        workspaceId: currentWorkspace.id,
        releaseId: selectedRelease.id,
      });
    }
  };

  useEffect(() => {
    if (currentWorkspace && selectedRelease) {
      fetchDocumentTypes(currentWorkspace.id);

      if (selectedRelease.id) {
        fetchDocumentTree({
          workspaceId: currentWorkspace.id,
          releaseId: selectedRelease.id,
        });
      } else {
        fetchDocumentTree({
          workspaceId: currentWorkspace.id,
          branch: selectedRelease.name,
        });
      }
    }
  }, [
    fetchDocumentTree,
    currentWorkspace,
    fetchDocumentTypes,
    selectedRelease,
  ]);

  const renderDocumentTree = () => {
    if (currentWorkspace && !currentWorkspace.isCodeGenerated) {
      return <span>{t("messages.error.no-repo-generated")}</span>;
    }

    if (!documentTree) {
      return <></>;
    }

    return (
      <DocumentTree
        currentWorkspaceSlug={currentWorkspace.slug}
        selectedReleaseSlug={selectedRelease.slug}
        documentTree={documentTree}
        isLoading={isLoadingDocumentTree}
        onAddFile={onAddDocsClick}
      />
    );
  };

  const renderAddFileButton = () => {
    return (
      <Button onClick={() => onAddDocsClick()}>
        <i className="pi pi-plus"></i>
      </Button>
    );
  };

  return (
    <Page
      title={
        <ReleasePageTitle title={t("title")} subtitle={selectedRelease.name} />
      }
      toolbar={renderAddFileButton()}
      loading={isLoadingDocumentTree || isFetchingGetDocumentTypes}
    >
      {currentWorkspace && (
        <>
          <AddDocsDialog
            documentTypes={documentTypes ?? []}
            onHide={() => setIsAddDocsDialogVisible(false)}
            visible={isAddDocsDialogVisible}
            workspaceId={currentWorkspace.id}
            onDocumentAdded={onDocumentAdded}
            targetDestination={targetDestination}
          />
          {renderDocumentTree()}
        </>
      )}
    </Page>
  );
};

export default Docs;

const ReleasePageTitle = ({
  title,
  subtitle,
}: {
  title: string;
  subtitle: string;
}) => {
  return (
    <>
      <p>{subtitle}</p>
      <h1>{title}</h1>
    </>
  );
};

export default ReleasePageTitle;

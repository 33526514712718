import { Page } from "@dzangolab/react-ui";
import { useEffect, useState } from "react";

import { Toolbar } from "./components";
import AddReleaseServiceDialog from "./components/AddReleaseServiceDialog";
import ReleaseGraph from "./components/ReleaseGraph";
import ReleasePageTitle from "../../components/common/ReleasePageTitle";
import { useCurrentWorkspace } from "../../hooks/UseCurrentWorkspace";
import { useLazyGetReleaseStatusesQuery } from "../../redux/apis/release";
import { useLazyGetServicesQuery } from "../../redux/apis/services";

const generatePageTitleAndSubtitle = (
  releaseName: string = "",
  releaseType: string = ""
): { title: string; subtitle: string } => {
  const lastSlashIndex = releaseName.lastIndexOf("/");
  let title = "";
  let subtitle = "";

  if (lastSlashIndex > 0) {
    title = releaseName.slice(lastSlashIndex + 1);
    subtitle = releaseType + " — " + releaseName.slice(0, lastSlashIndex + 1);
  } else {
    title = releaseName;
    subtitle = releaseType;
  }

  return { title, subtitle };
};

const Overview: React.FC = () => {
  const [addReleaseServiceDialogVisible, setAddReleaseServiceDialogVisible] =
    useState(false);
  const [getServices, { data: workspaceServices, isLoading: loadingServices }] =
    useLazyGetServicesQuery();
  const [fetchReleaseStatuses, { data: releaseStatuses }] =
    useLazyGetReleaseStatusesQuery();

  const { release: currentRelease, workspace: currentWorkspace } =
    useCurrentWorkspace();

  const { subtitle, title } = generatePageTitleAndSubtitle(
    currentRelease?.name,
    currentRelease?.type
  );

  useEffect(() => {
    fetchReleaseStatuses();

    if (currentWorkspace) {
      getServices({ workspaceId: currentWorkspace.id });
    }
  }, [currentWorkspace, fetchReleaseStatuses, getServices]);

  const renderReleaseGraph = () => {
    if (currentRelease && Object.hasOwnProperty.call(currentRelease, "apps")) {
      return (
        <ReleaseGraph
          release={currentRelease}
          onAddReleaseService={() => setAddReleaseServiceDialogVisible(true)}
        />
      );
    }
  };

  return (
    <Page
      className={"overview-page graph"}
      title={<ReleasePageTitle title={title} subtitle={subtitle} />}
      toolbar={<Toolbar releaseStatuses={releaseStatuses} />}
      loading={
        loadingServices ||
        (currentWorkspace?.isCodeGenerated ? !currentRelease : false)
      }
    >
      {renderReleaseGraph()}
      <AddReleaseServiceDialog
        visible={addReleaseServiceDialogVisible}
        onHide={() => setAddReleaseServiceDialogVisible(false)}
        release={currentRelease}
        workspaceServices={workspaceServices ?? []}
      />
    </Page>
  );
};

export default Overview;

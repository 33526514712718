import { TextInput } from "@dzangolab/react-form";
import { Button } from "@dzangolab/react-ui";

interface Properties {
  fieldName: string;
  index: number;
  onRemove: () => void;
}

const NameValuePairInput: React.FC<Properties> = ({
  fieldName,
  index,
  onRemove,
}) => {
  return (
    <div className="name-value-pair-input">
      <TextInput name={`${fieldName}[${index}].name`} placeholder="Name" />
      <TextInput name={`${fieldName}[${index}].value`} placeholder="Value" />
      <Button
        iconLeft="pi pi-trash"
        onClick={onRemove}
        severity="danger"
        size="large"
        type="button"
        variant="textOnly"
      />
    </div>
  );
};

export default NameValuePairInput;
